

.MuiIconButton-root {
  border-radius: 25% !important;
}
.MuiTableCell-root {
  border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
}
.MuiOutlinedInput-multiline {
  min-height: 100px !important;
}
.MuiSlider-mark {
  width: 1px !important;
  height: 8px !important;
  border-radius: 17px !important;
}


html{
  background-color: black;
  font-family: "panton";
}
body {
  margin: 0;
}

@font-face {
  font-family: "fontello";
  src: local("fontello"),
   url("../fonts/fontello.woff2") format("woff2");
  font-weight: bold;
 }


 @font-face {
  font-family: "panton";
  src: local("panton"),
   url("../fonts/panton-regular-webfont.woff2") format("woff2");
  font-weight: bold;
 }


 @font-face {
  font-family: "pantonbold";
  src: local("pantonbold"),
   url("../fonts/panton-bold-webfont.woff2") format("woff2");
  font-weight: bold;
 }

